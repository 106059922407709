import React, { ReactNode } from 'react'

const SubscriptionStatusProvider: React.FC<{
  onLoadingChange?: (state: boolean) => void
  children?: ReactNode
}> = ({ children, onLoadingChange }) => {
  return <>{children}</>
}

export default SubscriptionStatusProvider
