import { Button } from '@/components/shared/basic'
import { ShouldRender } from '@/components/shared/misc'
import { getTimeRemaining } from '@/utils/static-helpers/date'
import { JobContractType, JobSite, JobStatus, PayScale } from '@qureos/types'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { job as jobAtom } from '../../../../../src/atoms/jobs'
import { isUndefined } from 'lodash'

type JobViewDetailInfoProps = {
  salary: PayScale
  type: string
  location: string | undefined
  themeName: string
  isEnterprise: boolean
  parentCompanyName: string | undefined
  showPayToApprentices: boolean
  applyNowHandler: () => Promise<void>
  isExpired?: boolean
  isPublic?: boolean
  isAdmin: boolean
  workStyles?: string[]
  hideApplyCta?: boolean
  locations?: {
    location?: string
  }[]
}

export const JobViewDetailInfo: React.FC<JobViewDetailInfoProps> = ({
  isEnterprise,
  location,
  parentCompanyName,
  salary,
  themeName,
  type,
  showPayToApprentices,
  applyNowHandler,
  isAdmin,
  isExpired = false,
  isPublic = false,
  workStyles = [],
  hideApplyCta,
  locations
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const job = useRecoilValue(jobAtom)
  const router = useRouter()
  const { isApplied } = router.query

  const handleOnClick = async () => {
    try {
      setLoading(true)
      await applyNowHandler()
    } finally {
      setLoading(false)
    }
  }

  const getAdminStatusValue = () => {
    switch (job.status) {
      case JobStatus.Pending:
      case JobStatus.Draft:
        return (
          <p className="bg-status-warning bg-opacity-10 text-apprentice-yellow rounded text-sm py-1 px-3">
            {job.status}
          </p>
        )
      case JobStatus.Approved:
        return (
          <p className="bg-status-success bg-opacity-10 text-corporate-green rounded text-sm py-1 px-3">
            {job.closeDate
              ? getTimeRemaining(job.closeDate, {
                  prefix: 'Open for',
                  infix: 'more'
                })
              : job.status}
          </p>
        )

      case JobStatus.Closed:
      case JobStatus.Rejected:
      default:
        return (
          <p className="bg-status-error bg-opacity-10 text-status-error rounded text-sm py-1 px-3">
            {job.status}
          </p>
        )
    }
  }

  let buttonClasses = null
  switch (true) {
    case isExpired || !isUndefined(isApplied) || !!job?.appliedAt:
      buttonClasses = 'bg-red-500 cursor-not-allowed py-1'
      break
    case !isPublic && !isExpired:
      buttonClasses = 'bg-qureosPrimary pl-[18px] py-1'
      break
    case !isExpired && isPublic:
      buttonClasses = 'bg-qureosPrimary py-1'
      break
    default:
      buttonClasses = ''
      break
  }

  let buttonLabel = ''
  switch (true) {
    case isExpired:
      buttonLabel = 'Expired'
      break
    case !isExpired && (!isUndefined(isApplied) || !!job?.appliedAt):
      buttonLabel = 'Applied'
      break
    case !isExpired && isPublic:
      buttonLabel = 'Apply'
      break
    default:
      buttonLabel = 'Easy Apply'
      break
  }

  return (
    <div className="flex flex-col-reverse sm:flex-row justify-between py-6 gap-6">
      <div className="flex gap-x-[14.5px] gap-y-1 flex-col items-start">
        <ShouldRender check={showPayToApprentices && salary?.max > 0}>
          <div className="text-sm leading-5 mt-1">
            <span className="font-medium text-gray-500">Salary: </span>
            {salary?.max} {salary?.unit}
          </div>
        </ShouldRender>
        <ShouldRender
          check={locations?.length || workStyles?.length || location?.length}
        >
          <div className="flex flex-col justify-center items-start">
            <div className="flex gap-1 font-medium">
              <ShouldRender check={locations?.length || location?.length}>
                <span className="font-medium text-gray-500">Location: </span>
              </ShouldRender>
              <ShouldRender check={locations?.length}>
                <div className="flex flex-col">
                  {locations?.map(item => {
                    return (
                      <span
                        className="font-medium col-span-2"
                        key={item.location}
                      >
                        {item.location}
                      </span>
                    )
                  })}
                </div>
              </ShouldRender>
              <ShouldRender check={!locations && location?.length}>
                <div className="flex flex-col">{location}</div>
              </ShouldRender>
            </div>
            <ShouldRender check={workStyles?.length}>
              <div className="text-sm leading-5 mt-1">
                <span className="font-medium text-gray-500">Work Style: </span>
                {JobSite[workStyles[0]]}
              </div>
            </ShouldRender>
          </div>
        </ShouldRender>
        <ShouldRender check={type}>
          <div className="text-sm leading-5">
            <span className="font-medium text-gray-500">Job Type: </span>
            {JobContractType[type]}
          </div>
        </ShouldRender>
        <ShouldRender check={themeName}>
          <div className="text-sm leading-5">
            <span className="font-medium text-gray-500">Department: </span>
            {themeName}
          </div>
        </ShouldRender>
        <ShouldRender check={parentCompanyName}>
          <div className="flex justify-center items-center">
            <div className="py-[2px] px-3 rounded bg-blue-100 text-sm font-medium leading-5 text-[#1E40AF] uppercase">
              {parentCompanyName}
            </div>
          </div>
        </ShouldRender>
      </div>
      <div className="flex justify-center items-center">
        <ShouldRender check={!isAdmin}>
          {job?.application ? (
            <Link
              href={`/jobs/${job._id}/${
                !!job.caseStudy && !job.caseStudyNotRequired
                  ? 'apply'
                  : 'thank-you'
              }`}
            >
              <a className="bg-qureosPrimary rounded text-white px-4 py-2">
                View Application
              </a>
            </Link>
          ) : (
            <ShouldRender check={!hideApplyCta}>
              <Button
                rounded="normal"
                data-testid="button-text"
                disabled={
                  isExpired || !isUndefined(isApplied) || !!job?.appliedAt
                }
                className={clsx(
                  ' px-[26.5px] text-white rounded-md font-medium leading-6 whitespace-nowrap flex justify-center w-full sm:w-auto',
                  buttonClasses
                )}
                loading={loading}
                onClick={handleOnClick}
                theme="normal"
              >
                {buttonLabel}
              </Button>
            </ShouldRender>
          )}
        </ShouldRender>
        <ShouldRender check={isAdmin}>
          {job?.status && getAdminStatusValue()}
        </ShouldRender>
      </div>
    </div>
  )
}
