import clsx from 'clsx'
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode
} from 'react'
import { forOwn } from 'lodash'

//https://www.figma.com/file/V8zqMaZPLAKOZaaLHzLV0s/Qureos---Design-System?node-id=1267%3A18316

export enum ButtonVariants {
  WHITE = 'WHITE',
  PRIMARY = 'PRIMARY',
  UNSTYLED = 'UNSTYLED'
}
type ButtonSize = 1 | 2 | 3 | 4 | 5
type ButtonTheme = 'apprentice' | 'corporate'
interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size: ButtonSize
  variant: ButtonVariants
  theme?: ButtonTheme
  loading?: boolean
  children?: ReactNode
  disabled?: boolean
}
interface Styles {
  sizes: { [key in ButtonSize]: string }
}

const Spinner = () => (
  <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

const commonStyles = (theme: ButtonTheme) => ({
  [ButtonVariants.WHITE]: clsx(
    'text-gray-700 font-medium rounded-md bg-white border border-gray-300 hover:bg-gray-50 focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed',
    { 'focus:ring-qureosPrimaryLight': theme === 'apprentice' },
    { 'focus:ring-corporate-green': theme === 'corporate' }
  ),
  [ButtonVariants.PRIMARY]: clsx(
    'text-white font-medium rounded-md focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed',
    {
      'bg-qureosPrimary': theme === 'apprentice',
      'hover:bg-white hover:text-qureosPrimary hover:ring-2 hover:ring-qureosPrimary':
        theme === 'apprentice',
      'focus:ring-qureosPrimary': theme === 'apprentice'
    }
  ),
  [ButtonVariants.UNSTYLED]: 'font-inter'
})

const commonButtonSizes = {
  '1': clsx('px-[11px] py-[7px] text-xs'),
  '2': clsx('px-[13px] py-[9px] text-sm'),
  '3': clsx('px-[17px] py-[9px] text-sm'),
  '4': clsx('px-[17px] py-[9px] text-base'),
  '5': clsx('px-[25px] py-[13px] text-base')
}

const variantsToStylesMappings: (
  theme: ButtonTheme
) => Record<ButtonVariants, Styles> = theme => {
  const styles = {
    WHITE: {
      sizes: {
        ...commonButtonSizes
      }
    },
    PRIMARY: {
      sizes: {
        ...commonButtonSizes
      }
    },
    UNSTYLED: {
      sizes: {
        ...commonButtonSizes
      }
    }
  }
  forOwn(styles, (value, key) => {
    forOwn(value, (val, ke) => {
      forOwn(val, (v, k) => {
        styles[key][ke][k] = clsx(v, commonStyles(theme)[key])
      })
    })
  })
  return styles
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  size,
  theme = 'corporate',
  className,
  loading = false,
  disabled = false,
  ...props
}) => {
  return (
    <>
      <button
        disabled={disabled}
        className={clsx(
          variantsToStylesMappings(theme)[variant].sizes[size],
          className,
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          'flex items-center justify-center'
        )}
        {...props}
      >
        {loading && <Spinner />}
        {children}
      </button>
    </>
  )
}
