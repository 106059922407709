import { CorporateJobResponse, ThemeType } from '@qureos/types'
import { format } from 'date-fns'
import { kebabCase } from 'lodash'
import { SeoMetaType } from 'src/types/seo'
import { removeEmptyValues } from '../object'

interface JobSeoMetaInterface {
  searchQuery?: string
  selectedLocation?: string
  // themes in this case is called department on FE
  selectedTheme?: string | string[]
  allThemes: ThemeType[]
  jobId?: string
  jobTotal?: number
  jobTitle?: string
  companyName?: string
  companyLocation?: string
}

export const getJobSeoMeta = (params: JobSeoMetaInterface): SeoMetaType => {
  const {
    searchQuery,
    selectedLocation,
    selectedTheme,
    allThemes,
    jobId,
    jobTotal,
    jobTitle,
    companyName,
    companyLocation
  } = params

  let themeIds = selectedTheme as string[]
  if (!selectedTheme) {
    themeIds = []
  }
  if (selectedTheme && !Array.isArray(selectedTheme)) {
    themeIds = [selectedTheme]
  }

  const themeLabels = themeIds.map(
    themeId => allThemes.find(theme => theme._id === themeId)?.label || ''
  )

  const PAGE_PREFIX = `${process.env.APP_URL}/jobs`

  const seoMeta: SeoMetaType = {
    heading: '',
    title:
      'Qureos Jobs -  Find Your Dream Career - Millions of Job Opportunities Near You',
    description:
      "Welcome to Qureos Jobs, the ultimate destination for finding your dream career! With our extensive database, we offer millions of job opportunities that are conveniently located near you. Whether you're a recent graduate, a seasoned professional, or someone looking to make a career change, we've got the perfect match for you.",
    canonical: PAGE_PREFIX
  }

  const metaLocation = selectedLocation
    ? decodeURIComponent(selectedLocation)
    : ''

  if (jobId) {
    seoMeta.canonical = `${PAGE_PREFIX}?${jobId}`

    if (jobTitle) {
      seoMeta.title = `${jobTitle}`
      seoMeta.description = `Explore new opening for ${jobTitle} position. Explore more on app.qureos.com/jobs.`

      if (companyName) {
        seoMeta.title = `${jobTitle} - ${companyName}`
        seoMeta.description = `${companyName} is now hiring for ${jobTitle} position. Explore more on app.qureos.com/jobs.`

        if (companyLocation) {
          seoMeta.title = `${jobTitle} - ${companyName}`
          seoMeta.description = `${companyName} is now hiring for ${jobTitle} position in ${companyLocation}. Explore more on app.qureos.com/jobs.`
        }
      }
    }
  }

  if (searchQuery) {
    seoMeta.heading = `Search results for "${searchQuery}"`
    seoMeta.title = 'Explore Latest Jobs available on Qureos'
    seoMeta.canonical = `${PAGE_PREFIX}/search/${kebabCase(searchQuery)}-jobs-near-me`

    if (jobTotal) {
      seoMeta.title = `${jobTotal}+ ${searchQuery} jobs (${format(new Date(), 'MMM yyyy')})`
    }

    if (selectedLocation) {
      seoMeta.heading = `"${searchQuery}" in ${metaLocation}`
      seoMeta.title = `"${searchQuery}" in ${metaLocation} - Qureos`
      seoMeta.description = `${searchQuery} jobs in ${metaLocation} on Qureos`
      seoMeta.canonical = `${PAGE_PREFIX}/search/${kebabCase(
        searchQuery
      )}-in-${kebabCase(metaLocation)}`
    }
  }

  if (!searchQuery && metaLocation) {
    seoMeta.heading = `Jobs in ${metaLocation}`
    seoMeta.title = `Jobs in ${metaLocation} - Apply for jobs`
    seoMeta.description = `Jobs now available in ${metaLocation}, more jobs on app.qureos.com/jobs`
    seoMeta.canonical = `${PAGE_PREFIX}/search/in-${kebabCase(metaLocation)}`
  }

  if (themeLabels?.length) {
    if (themeLabels.length === 1) {
      seoMeta.heading = `${themeLabels[0]} Jobs`
      seoMeta.title = `${themeLabels[0]} Jobs near me - Apply for jobs at Qureos`
      seoMeta.description = `${themeLabels[0]} Jobs now available near you, more jobs on app.qureos.com/jobs`
      seoMeta.canonical = `${PAGE_PREFIX}/search/${kebabCase(
        themeLabels[0]
      )}-jobs-near-me`

      if (metaLocation) {
        seoMeta.heading = `${themeLabels[0]} jobs in ${metaLocation}`
        seoMeta.title = `${themeLabels[0]} jobs in ${metaLocation} - Apply for jobs at Qureos`
        seoMeta.description = `${themeLabels[0]} jobs now available in ${metaLocation}, Explore more related jobs on app.qureos.com/jobs`
        seoMeta.canonical = `${PAGE_PREFIX}/search/${kebabCase(
          themeLabels[0]
        )}-jobs-in-${kebabCase(metaLocation)}`
      }
    }

    if (themeLabels.length > 1) {
      seoMeta.title = 'Explore Latest Jobs available on Qureos'
      seoMeta.description =
        'Explore Jobs now available near you on app.qureos.com/jobs'

      if (metaLocation) {
        seoMeta.heading = `Jobs available in ${metaLocation}`
        seoMeta.title = `Explore Jobs available in ${metaLocation}`
        seoMeta.description = `Jobs now available in ${metaLocation}. ${themeLabels.join(', ')} and more on app.qureos.com/jobs`
      }
    }
  }

  return {
    ...seoMeta,
    ogMeta: {
      title: seoMeta?.title,
      description: seoMeta.description,
      siteName: 'Qureos',
      url: PAGE_PREFIX
    }
  }
}

export const getSeoJobPostingSchema = (job: CorporateJobResponse) => {
  const jobPostingSchema = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: job?.title,
    description: job?.description,
    directApply: !job?.isExternal && !job?.isConfidential,
    hiringOrganization: {
      '@type': 'Organization',
      name: job?.company?.name,
      sameAs: job?.company?.website,
      logo: job?.company?.logo
    },
    industry: job?.theme?.label,
    datePosted: job?.createdDateTime,
    validThrough: job?.closeDate,
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: job?.city,
        addressCountry: job?.country
      }
    }
  }

  return JSON.stringify(removeEmptyValues(jobPostingSchema), null, 2)
}
