import { placeHolderLogo } from '@qureos/assets'
import { useState, useEffect, FC } from 'react'
import { Spinner } from '../../basic'

type ImageProps = {
  src: string
  alt?: string
  fallbackSrc?: string
  className?: string
  wrapperClass?: string
  showLoader?: boolean
  imageStyle?: unknown
}

const Image: FC<ImageProps> = ({
  src: initSrc,
  fallbackSrc = placeHolderLogo,
  className = '',
  wrapperClass = '',
  alt = '',
  showLoader = false,
  imageStyle = {}
}) => {
  const [src, setSrc] = useState(initSrc)
  const [loading, setLoading] = useState(false)

  const handleError = () => {
    if (fallbackSrc && src !== fallbackSrc) {
      setSrc(fallbackSrc)
    }
  }

  useEffect(() => {
    if (initSrc !== src) {
      setSrc(initSrc || fallbackSrc)
      setLoading(true)
    }
  }, [initSrc])

  return (
    <div
      className={
        wrapperClass
          ? wrapperClass
          : 'relative flex items-center justify-center'
      }>
      <img
        src={src || fallbackSrc}
        onError={handleError}
        className={`${className} ${showLoader && loading ? 'opacity-10' : ''}`}
        alt={alt}
        onLoad={() => {
          setLoading(false)
        }}
        style={imageStyle}
      />
      {showLoader && loading && <Spinner className="absolute" />}
    </div>
  )
}

export default Image
