import { Loading } from '@/components/shared/basic/Loading'
import { loginRedirect } from '@qureos/lib'

import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect, useMemo } from 'react'

function withRedirectUrl<T>(Page: React.ComponentType<T>) {
  return function Comp(props: PropsWithChildren<T>): JSX.Element {
    const router = useRouter()
    const redirectUrl = loginRedirect('get')
    const onboardingWhitelistedPages = [
      /\/waitlist\/.+/g,
      /\/enrollment\/.+\/payment.*$/g,
      /\/transaction\/.+\/success.*$/g,
      /\/enrollment\/.+\/scholarship.*$/g,
      /\/subscription\/choose-plan.*$/g,
      /\/learning-hub\/.+\/choose-plan.*$/g,
      /\/payment\/.+\/success.*$/g,
      /\/learning-path\/.+/g,
      /\/masterclass\/.+/g,
      /\/apprentice-onboarding/g
    ]
    const matchFound = useMemo(() => {
      const path = router.asPath.split('?')[0]
      return onboardingWhitelistedPages.some(page => page.test(path))
    }, [])

    useEffect(() => {
      if (redirectUrl && !matchFound) {
        loginRedirect('remove')
        router.push(redirectUrl)
      }
    }, [])
    return <>{redirectUrl && !matchFound ? <Loading /> : <Page {...props} />}</>
  }
}

export default withRedirectUrl
