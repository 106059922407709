import moment from 'moment'
import 'moment-timezone'

moment.updateLocale('en', {
  relativeTime: {
    future: '%s'
  }
})

export const addMissingMomentTimezones = (): void => {
  moment.tz.add('Asia/Dubai|LMT GST +04|-3F.c -40|01|-21JfF.c|39e5')
}

export const getFormattedTime = (
  timestamp: number,
  relative = false
): string => {
  const momentTime = moment.unix(timestamp)
  const yesterday = moment().clone().subtract(1, 'days').startOf('day')

  if (momentTime.isSame(moment(), 'day'))
    return relative
      ? momentTime.format('HH:mm')
      : momentTime.format('h:mm A DD MMM YYYY')
  else if (momentTime.isSame(yesterday, 'd')) {
    return relative ? 'Yesterday' : momentTime.format('h:mm A DD MMM YYYY')
  } else {
    return relative
      ? momentTime.format('DD/MM/YYYY')
      : momentTime.format('h:mm A DD MMM YYYY')
  }
}

type FormatOptions = Partial<{
  prefix: string
  infix: string
  suffix: string
}>

export const getTimeRemaining = (
  timeString: string,
  { prefix = '', infix = '', suffix = '' }: FormatOptions,
  pastOptions?: FormatOptions
): string => {
  const time = moment(timeString)
  if (!time.isAfter() && pastOptions) {
    ;({ prefix = '', infix = '', suffix = '' } = pastOptions)
  }
  const [count, unit] = time.fromNow().split(' ')
  return `${prefix} ${count === 'a' ? '1' : count} ${infix} ${unit} ${suffix}`
}
