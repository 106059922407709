import { user } from 'src/atoms'
import Router from 'next/router'
import { FunctionComponent, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useRecoilValue } from 'recoil'
import { User } from 'src/types/shared/graphql'

export const GAAnalytics: FunctionComponent = () => {
  const profile = useRecoilValue<User>(user)

  useEffect(() => {
    if (profile) {
      ReactGA.set({
        userId: profile.id
      })
    }
  }, [profile])

  useEffect(() => {
    ReactGA.pageview(Router.pathname)
  })

  return null
}
