import { Photo } from '@/components/shared/misc'
import { qureosSidemenuWhiteLogo } from '@qureos/assets'
import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { useRecoilValue } from 'recoil'
import { sidebarOpen } from 'src/atoms'
import { UserRole } from 'src/types/shared/graphql'
import DefaultSidebar from './DefaultSidebar'
import { SideMenu } from './SideMenu'

interface Props {
  bgColor?: string
  role: UserRole
  children?: ReactNode
}

const Sidebar: FC<Props> = ({ bgColor, role, children }) => {
  const isOpen = useRecoilValue<boolean>(sidebarOpen)
  const color = bgColor ?? 'bg-qureosPrimary'
  const width = 'w-24'

  const roleLabel = {
    STUDENT: 'Candidate',
    MENTOR: 'Mentor',
    PROFESSIONAL: 'Corporate',
    ADMIN: 'Admin',
    ENTERPRISE: 'Enterprise'
  }

  const SidebarContent: FC<{ width: string }> = ({ width = 'w-24' }) => (
    <div className={clsx('h-full fixed overflow-hidden transition-all', width)}>
      <div className="w-full h-full flex flex-col justify-between overflow-auto">
        <div className="w-full h-16 p-2 flex items-center justify-center">
          <Photo
            url={qureosSidemenuWhiteLogo}
            title="Qureos"
            className="h-10"
          />
        </div>
        {children}
        <SideMenu showLabel role={role} />
        <div className="relative bottom-0 text-white uppercase text-center py-2 bg-black bg-opacity-25">
          {roleLabel[role] || ''}
        </div>
      </div>
    </div>
  )

  const defaultSidebarClass = 'relative flex-none transition-all'

  return (
    <>
      {role === UserRole.Student ||
      role === UserRole.Mentor ||
      role === UserRole.Professional ? (
        <>
          <DefaultSidebar role={role} bgColor="bg-white" />
        </>
      ) : (
        <>
          <div
            className={clsx(
              'hidden md:inline',
              width,
              defaultSidebarClass,
              color
            )}>
            <SidebarContent width={width} />
          </div>
          <div
            className={clsx(
              'md:hidden',
              'overflow-hidden',
              defaultSidebarClass,
              color,
              isOpen ? 'w-24' : 'w-0'
            )}>
            <SidebarContent width={isOpen ? 'w-24' : 'w-0'} />
          </div>{' '}
        </>
      )}
    </>
  )
}

export default Sidebar
