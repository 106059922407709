import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode } from 'react'

type Props = {
  href?: string
  children?: ReactNode
}

export const NavLink: FunctionComponent<Props> = ({ children, href }) => {
  const { asPath } = useRouter()

  const classes: string[] = ['p-8']

  if (asPath === href) {
    classes.push('font-semibold')
  }

  return (
    <Link href={href}>
      <a className={clsx(classes)}>{children}</a>
    </Link>
  )
}
