export const JobDetailViewFooter: React.FC = () => {
  return (
    <>
      <div className="font-extrabold text-base leading-5 mb-[18px]">
        Desclaimer
      </div>
      <div className="font-normal text-sm leading-5 text-gray-600">
        This is a special opening powered by Tumoohi Internship Program. Special
        conditions apply.
      </div>
    </>
  )
}
