import { HomeIcon } from '@heroicons/react/24/solid'
import { EventType } from '@qureos/types'

interface BreadCrumbParams {
  searchSlug?: string
  eventtype?: string | string[]
  eventID?: string | string[]
  routePath: string
  screens: any
}

const getLabelIcon = (screens, smallScreenIcon, largeScreenIcon) => {
  if (screens?.sm) {
    return smallScreenIcon
  } else {
    return largeScreenIcon
  }
}

const getLearninghubLabel = screens => {
  return getLabelIcon(
    screens,
    'Learning Hub',
    <HomeIcon className="inline-block w-4 h-4" />
  )
}

const getLabel = (type: EventType) => {
  switch (type) {
    case EventType.LEARNINGPATH: {
      return 'Learning Path'
    }
    case EventType.MASTERCLASS: {
      return 'Masterclass'
    }
    case EventType.MENTORSHIP: {
      return 'Mentorship'
    }
  }
}

const getPath = (type: EventType, id: string) => {
  switch (type) {
    case EventType.LEARNINGPATH: {
      return `/${type}/${id}`
    }
    case EventType.MASTERCLASS: {
      return `/${type}/${id}`
    }
    case EventType.MENTORSHIP: {
      return `/profile/${id}`
    }
  }
}

export const getBreadCrumbItems = ({
  searchSlug,
  eventtype,
  eventID,
  routePath,
  screens
}: BreadCrumbParams) => {
  const learningHubBreadcrumb = {
    key: 'learning-hub',
    label: getLearninghubLabel(screens),
    path: '/learning-hub'
  }

  const learningPathsBreadcrumb = [
    learningHubBreadcrumb,
    {
      key: 'learning-paths',
      label: 'Learning Paths',
      path: '/learning-hub/learning-paths'
    }
  ]

  const masterclassesBreadcrumb = [
    learningHubBreadcrumb,
    {
      key: 'masterclasses',
      label: 'Masterclasses',
      path: '/learning-hub/masterclasses'
    }
  ]

  const detailsBreadcrumb = (label: string, path: string) => [
    learningHubBreadcrumb,
    {
      key: eventtype,
      label,
      path
    }
  ]

  const choosePlanBreadcrumb = [
    learningHubBreadcrumb,
    {
      key: eventtype,
      label: getLabel(eventtype as EventType),
      path: getPath(eventtype as EventType, eventID as string)
    },
    {
      key: 'choose-plan',
      label: 'Select your Plan',
      path: `/learning-hub/${eventtype}/${eventID}/choose-plan`
    }
  ]

  return {
    '/learning-hub': [learningHubBreadcrumb],
    '/manage-plan': [
      { key: 'manage-plan', label: 'Manage Plan', path: '/manage-plan' }
    ],
    '/manage-plan/cancel': [
      { key: 'manage-plan', label: 'Manage Plan', path: '/manage-plan' },
      { key: 'cancel', label: 'Cancel', path: '/manage-plan/cancel' }
    ],
    '/learning-hub/mentors': [
      ...learningPathsBreadcrumb,
      { key: 'mentors', label: 'Mentors', path: '/learning-hub/mentors' }
    ],
    '/learning-hub/learning-paths': learningPathsBreadcrumb,
    '/learning-hub/learning-paths/completed': [
      ...learningPathsBreadcrumb,
      {
        key: 'learning-paths',
        label: 'Completed Learning Paths',
        path: '/learning-hub/learning-paths/completed'
      }
    ],
    '/learning-hub/learning-paths/enrolled': [
      ...learningPathsBreadcrumb,
      {
        key: 'learning-paths',
        label: 'Enrolled Learning Paths',
        path: '/learning-hub/learning-paths/enrolled'
      }
    ],
    '/learning-hub/masterclasses': masterclassesBreadcrumb,
    '/learning-hub/masterclasses/completed': [
      ...masterclassesBreadcrumb,
      {
        key: 'masterclasses',
        label: 'Completed Masterclasses',
        path: '/learning-hub/masterclasses/completed'
      }
    ],
    '/learning-hub/masterclasses/enrolled': [
      ...masterclassesBreadcrumb,
      {
        key: 'masterclasses',
        label: 'Enrolled Masterclasses',
        path: '/learning-hub/masterclasses/enrolled'
      }
    ],
    [`/learning-hub/${eventtype}`]: detailsBreadcrumb(
      'Details',
      `/learning-hub/${eventtype}`
    ),
    '/learning-hub/master-classes-profile': [
      ...masterclassesBreadcrumb,
      {
        key: 'master-class',
        label: 'Details',
        path: '/learning-hub/master-class'
      }
    ],
    [`/learning-hub/${eventtype}/${eventID}/choose-plan`]: choosePlanBreadcrumb,
    '/subscription/choose-plan': [
      {
        key: 'subscription',
        label: 'Subscription',
        path: '/subscription/choose-plan'
      }
    ],
    '/learning-hub/[id]': [
      learningHubBreadcrumb,
      { key: 'learning-path-details', label: 'Learning Path', path: routePath }
    ],
    '/masterclass/[id]': [
      learningHubBreadcrumb,
      { key: 'masterclass-details', label: 'Masterclass', path: routePath }
    ],
    '/projects/[id]': [
      learningHubBreadcrumb,
      { key: 'projects-details', label: 'Project', path: routePath }
    ],
    '/projects': [
      {
        key: 'learning-hub',
        label: screens.sm ? (
          'Learning Hub'
        ) : (
          <HomeIcon className="inline-block w-4 h-4" />
        ),
        path: '/learning-hub'
      },
      { key: 'projects', label: 'Projects', path: '/Projects' }
    ],
    '/resources': [
      { key: 'resources', label: 'Resources', path: '/resources' }
    ],
    '/jobs': [
      {
        key: 'dashboard',
        label: getLabelIcon(
          screens,
          'Dashboard',
          <HomeIcon className="inline-block w-4 h-4" />
        ),
        path: '/learning-hub'
      },
      { key: 'jobs', label: 'Jobs', path: '/jobs' }
    ],
    '/jobs/search/[slug]': [
      {
        key: 'dashboard',
        label: getLabelIcon(
          screens,
          'Dashboard',
          <HomeIcon className="inline-block w-4 h-4" />
        ),
        path: '/learning-hub'
      },
      { key: 'jobs', label: 'Jobs', path: '/jobs' },
      {
        key: 'search',
        label: searchSlug?.length ? searchSlug : 'Search',
        path: routePath
      }
    ],
    '/settings': [
      {
        key: 'dashboard',
        label: getLabelIcon(
          screens,
          'Dashboard',
          <HomeIcon className="inline-block w-4 h-4" />
        ),
        path: '/learning-hub'
      },
      { key: 'settings', label: 'Settings', path: '/settings' }
    ]
  }
}
