export enum UserAnalyticsEventName {
  PUBLIC_JOB = 'public-job'
}

export enum UserAnalyticsEventType {
  REDIRECT = 'redirect'
}

export interface UserAnalyticsEvent {
  _id: string
  name: UserAnalyticsEventName
  type: UserAnalyticsEventType
}
