export const testImage =
  'https://storage.googleapis.com/qureos-prod/static/test-images/Image_test.png'

export const qureosLogoSquare =
  'https://storage.googleapis.com/qureos-prod/static/logos/Qureos-Square.png'

export const poweredByQureosDark =
  'https://storage.googleapis.com/qureos-prod/static/logos/powered-by-qureos.png'

export const certificatePreview =
  'https://storage.googleapis.com/qureos-prod/static/banners/Certificate-Preview.png'

export const irisButton =
  'https://storage.googleapis.com/qureos-prod/static/banners/Iris-Button.svg'
export const irisCrossSell =
  'https://storage.googleapis.com/qureos-prod/static/banners/Iris-Cross-Sell-2.svg'

export const mentorAuthBanner =
  'https://storage.googleapis.com/qureos-prod/static/banners/Mentor_Banner.jpg'
