import { QureosCorporateLogo, qureosRevisedLogo } from '@qureos/assets'
import { isLoggedIn, loginRedirect } from '@qureos/lib'
import { UserRole } from '@qureos/types'
import { omit } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { Button, ButtonVariants } from '../../basic/Buttons/V2'
import { NavLink } from '../NavLink'
import { ShouldRender } from '../ShouldRender'

interface Props {
  loggedIn?: boolean
  userRole?: UserRole
  headerActions?: JSX.Element
}

export const Header: FunctionComponent<Props> = ({
  loggedIn,
  userRole = UserRole.STUDENT,
  headerActions
}) => {
  const router = useRouter()

  const handleRedirect = (path: string) => {
    loginRedirect('set', router.asPath)

    // Removed public job related filters on login/signup redirected from header
    const keysToRemove = [
      'location',
      'theme',
      'datePosted',
      'easyApply',
      'query',
      'pageNumber',
      'jobId'
    ]
    const formattedQueries = omit({ ...router.query }, keysToRemove)

    router.push({ pathname: path, query: formattedQueries })
  }

  const onLogin = () => {
    switch (userRole) {
      case UserRole.MENTOR:
        handleRedirect('/mentor/signin')
        break
      case UserRole.PROFESSIONAL:
        handleRedirect('/corporate/login')
        break
      default:
        handleRedirect('/')
        break
    }
  }

  const onSignup = () => {
    switch (userRole) {
      case UserRole.MENTOR:
        handleRedirect('/mentor/signup')
        break
      case UserRole.PROFESSIONAL:
        handleRedirect('/corporate/signup')
        break
      default:
        handleRedirect('/register')
        break
    }
  }

  const headerLogo = () => {
    switch (userRole) {
      case UserRole.MENTOR:
        return qureosRevisedLogo
      case UserRole.PROFESSIONAL:
        return QureosCorporateLogo
      default:
        return qureosRevisedLogo
    }
  }

  return (
    <header className="items-center flex justify-between sticky top-0 bg-white shadow-lg w-full z-50 px-4 py-2 md:px-8 md:py-4">
      <Link href="/">
        <a>
          <img
            className="hidden md:inline-block w-24"
            src={headerLogo()}
            alt="Qureos"
          />
        </a>
      </Link>
      {loggedIn && (
        <nav className="flex">
          <NavLink href="/logout">Sign out</NavLink>
        </nav>
      )}
      <ShouldRender check={!isLoggedIn() && !headerActions}>
        <nav className="flex gap-2 md:gap-4">
          <span
            className="cursor-pointer hover:text-qureosPrimary p-2 transition-colors"
            onClick={onLogin}
          >
            Login
          </span>
          <Button
            onClick={onSignup}
            size={2}
            theme="apprentice"
            variant={ButtonVariants.PRIMARY}
          >
            Sign up
          </Button>
        </nav>
      </ShouldRender>
      <ShouldRender check={headerActions && !isLoggedIn()}>
        {headerActions}
      </ShouldRender>
    </header>
  )
}
