import Image from '@/components/shared/misc/Photo/Image'
import { JobGlobeAmericasIcon, LocationMarker } from '@qureos/assets'
import { SeeMore } from '@/components/shared/misc/SeeMore'
import { poweredByQureosDark } from '@/utils/static-helpers/images'
import Link from 'next/link'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ShouldRender } from '@/components/shared/misc'

type JobViewDetailCoverType = {
  coverPhoto: string | undefined
  companyLogo: string | undefined
  companyName: string
  companyHandle: string | undefined
  location: string | undefined
  website: string | undefined
  brief: string | undefined
  jobTitle?: string
  jobLink?: string
}

export const JobViewDetailCover: React.FC<JobViewDetailCoverType> = ({
  companyName,
  brief,
  companyLogo,
  companyHandle,
  coverPhoto,
  location,
  website,
  jobTitle,
  jobLink
}) => {
  return (
    <div className="mt-4 md:mt-0 border rounded-lg border-qureosPrimary overflow-hidden">
      {coverPhoto && (
        <div
          className="flex justify-center items-center"
          data-testid="coverPhoto"
        >
          <div className="flex justify-between items-center w-full h-[171px] overflow-hidden">
            <img
              src={coverPhoto}
              alt="cover"
              className="w-full object-contain"
            />
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row justify-between my-3 lg:my-6 mx-3 lg:mx-10">
        <div className="flex gap-4">
          <div className="flex justify-center items-center">
            <div className="flex justify-between items-center h-[95px] w-[99px] border border-gray-200 rounded-md">
              <Image
                src={companyLogo}
                fallbackSrc={poweredByQureosDark}
                className="w-28 h-20 px-2 object-scale-down"
                alt="companyLogo"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center">
            {typeof companyHandle === 'undefined' ? (
              companyName
            ) : (
              <Link href={`${process.env.APP_URL}/company/${companyHandle}`}>
                <a
                  className="text-gray-500 text-xs max-w-[219px] whitespace-nowrap text-ellipsis overflow-hidden underline flex gap-2"
                  target="_blank"
                >
                  {companyName}
                  <ArrowTopRightOnSquareIcon className="w-4" />
                </a>
              </Link>
            )}
            <div className="text-gray-700 font-semibold my-1 text-xl capitalize">
              <ShouldRender
                check={typeof jobLink === 'string' && jobLink?.length > 0}
              >
                <Link href={jobLink}>
                  <a target="_blank" className="flex gap-2 underline">
                    {jobTitle} <ArrowTopRightOnSquareIcon className="w-5" />
                  </a>
                </Link>
              </ShouldRender>
              <ShouldRender check={typeof jobLink !== 'string'}>
                {jobTitle}
              </ShouldRender>
            </div>
            <div>
              {location && (
                <div className="flex mb-2">
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center w-4 h-4">
                      <div className="w-4 h-4">
                        <img src={LocationMarker} alt="location marker" />
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 text-gray-500 text-xs max-w-[219px] whitespace-nowrap text-ellipsis overflow-hidden">
                    {location}
                  </div>
                </div>
              )}
              {website && (
                <div className="flex">
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center w-4 h-4">
                      <div className="w-4 h-4">
                        <img src={JobGlobeAmericasIcon} alt="globe" />
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 text-gray-500 text-xs leading-4 max-w-[219px] whitespace-nowrap text-ellipsis overflow-hidden">
                    {website}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {brief && (
          <div className="flex flex-col justify-start w-1/2">
            <div className="text-gray-700 font-semibold text-sm leading-5 mb-2 mt-3">
              About Employer:
            </div>
            <div className="max-w-[476px] text-sm leading-5 text-gray-600">
              <SeeMore text={brief} maxCharacters={100} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
