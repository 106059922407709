export enum EventType {
  WEBINAR = 'webinar',
  COHORT = 'cohort',
  COURSE = 'course',
  WORKSHOP = 'workshop',
  LEARNING_PATH = 'learning_path',
  LEARNINGPATH = 'learning-path',
  MASTERCLASS = 'masterclass',
  SUBSCRIPTION = 'subscription',
  MENTORSHIP = 'mentorship',
  PROJECT = 'project'
}
