import { Parser } from '@/components/shared/basic'

type JobDetailViewDescriptionProps = {
  description: string
}

export const JobDetailViewDescription: React.FC<
  JobDetailViewDescriptionProps
> = ({ description }) => {
  return (
    <>
      <div className="font-extrabold text-base leading-5 mb-[18px]">
        Job Description
      </div>
      <div className="font-normal text-sm leading-5 text-gray-600 job-description">
        <Parser content={description?.trim()} />
      </div>
    </>
  )
}
