import { Parser } from '@/components/shared/basic'

import clsx from 'clsx'
import React, { useState } from 'react'
import { ShouldRender } from '@/components/shared/misc'

const MAX_CHARS = 160

interface SeeMoreProps {
  text: string
  className?: string
  btnClassName?: string
  maxCharacters?: number
}

/* 
  This component truncates text if its length is more than the specified length and shows a button which toggles the visibility of the text
*/
export const SeeMore: React.FC<SeeMoreProps> = ({
  text,
  className,
  btnClassName,
  maxCharacters = MAX_CHARS
}) => {
  const showSeeMoreButton = text?.length > maxCharacters
  const [fullTextShown, setFullTextShown] = useState(false)

  return (
    <div>
      <div className={clsx('text-base', className)}>
        <Parser
          className="text-sm"
          content={
            showSeeMoreButton && !fullTextShown
              ? text?.substring(0, maxCharacters) + '...'
              : text
          }
        />
      </div>
      <ShouldRender check={showSeeMoreButton}>
        <div
          onClick={() => setFullTextShown(!fullTextShown)}
          className={
            btnClassName
              ? `${btnClassName}`
              : 'underline text-blue-700 mt-2 cursor-pointer'
          }>
          Show {fullTextShown ? ' Less' : ' More'}
        </div>
      </ShouldRender>
    </div>
  )
}
