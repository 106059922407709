import { DEFAULT_USER_SIGNUP_CODE } from 'statics/global'
import { PageInterface, SidebarProps } from 'statics/interface'
import { MENU } from '@/utils/static-helpers/sidebar-helper'
import clsx from 'clsx'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { Tooltip } from 'antd'
import { User } from 'src/types/shared/graphql'
import { user as userAtom } from 'src/atoms'
import { useRecoilValue } from 'recoil'

export const SideMenu: FunctionComponent<SidebarProps> = ({
  showLabel,
  role,
  signupCode
}) => {
  if (!role) {
    return null
  }
  const user = useRecoilValue<User>(userAtom)
  const isVfsServiceEnable = user?.defaultCompany?.isVfs || false

  const pages = filter(MENU(isVfsServiceEnable), item =>
    item.permissions.includes(role)
  )
  const router = useRouter()
  const labelClass: string[] = ['text-sm', 'font-base', 'normal-case']

  const filterPages = (page: PageInterface) =>
    !page.restriction.includes(signupCode || DEFAULT_USER_SIGNUP_CODE)
  const isActive = (pathname: string) => router.pathname.includes(pathname)

  return (
    <>
      <div className="flex-1 flex flex-col">
        {map(filter(pages, filterPages), page => (
          <Link
            href={page.disabled ? '#' : page.url}
            key={kebabCase(`sidebar-route-${page.pathname}`)}
          >
            <Tooltip title={page.tooltipText}>
              <a
                title={page.tooltipText}
                className={clsx(
                  'text-indigo-100 hover:bg-black hover:bg-opacity-25 hover:text-white group w-full px-1 py-3 flex flex-col items-center',
                  isActive(page.pathname) ? 'bg-black bg-opacity-25' : ''
                )}
              >
                {isActive(page.pathname) ? page.icon.active : page.icon.normal}
                {showLabel && (
                  <div className={clsx(labelClass, 'text-white pt-2 pb-1')}>
                    {page.label}
                  </div>
                )}
              </a>
            </Tooltip>
          </Link>
        ))}
      </div>
    </>
  )
}
