import { Apprentices } from 'src/types/apprentices'
import apiClient from '../base'
import {
  ApprenticeVoiceIntroResponse,
  VoiceIntroRequestPayload
} from 'src/types/voice-intro/voice-intro-question'

export const getApprentices = async (id: string) => {
  const response = await apiClient.get(`/apprentices/${id}`)
  return response
}

export const updateApprenticesProfessionalDetails = async data => {
  const resp = await apiClient.patch('/apprentices/professional-details', data)
  return resp.data
}

export const updateApprenticesResume = async data => {
  const resp = await apiClient.post(
    '/apprentices/professional-details/resumes',
    data
  )
  return resp.data
}

export const upsertApprenticeClubs = async data => {
  const resp = await apiClient.put('/apprentices/clubs', data)
  return resp.data
}

export const createClub = async data => {
  const value = { items: [data] }
  const resp = await apiClient.post('/apprentices/clubs', value)
  return resp.data
}

export const updateClub = async (data, id: string) => {
  const resp = await apiClient.patch(`/apprentices/clubs/${id}`, data)
  return resp.data
}

export const deleteClub = async (id: string) => {
  const resp = await apiClient.delete(`/apprentices/clubs/${id}`)
  return resp
}

export const getClubTypes = async () => {
  const resp = await apiClient.get('/apprentices/clubs/types')
  return resp.data
}

export const upsertApprenticeWorkHistory = async data => {
  const resp = await apiClient.put('/apprentices/work-history', data)
  return resp.data
}

export const updateApprentices = async (data: Partial<Apprentices>) => {
  const resp = await apiClient.patch('/apprentices', data)
  return resp.data
}

export const updateApprenticesPreferences = async data => {
  const resp = await apiClient.patch('/apprentices/preferences', data)
  return resp.data
}

export const createEducationHistory = async data => {
  const value = { items: [data] }
  const resp = await apiClient.post('/apprentices/education-history', value)
  return resp.data
}

export const updateEducationHistory = async (data, id: string) => {
  const resp = await apiClient.patch(
    `/apprentices/education-history/${id}`,
    data
  )
  return resp.data
}

export const createBootcamps = async data => {
  const value = { items: [data] }
  const resp = await apiClient.post('/apprentices/bootcamps', value)
  return resp.data
}

export const updateBootcamp = async (data, id: string) => {
  const resp = await apiClient.patch(`/apprentices/bootcamps/${id}`, data)
  return resp.data
}

export const deleteBootcamp = async (id: string) => {
  const resp = await apiClient.delete(`/apprentices/bootcamps/${id}`)
  return resp
}

export const createExternalCertificate = async data => {
  const value = { items: [data] }
  const resp = await apiClient.post('/apprentices/external-certificates', value)
  return resp.data
}

export const updateExternalCertificate = async (data, id: string) => {
  const resp = await apiClient.patch(
    `/apprentices/external-certificates/${id}`,
    data
  )
  return resp.data
}

export const updateVoiceIntro = async (
  data: VoiceIntroRequestPayload
): Promise<ApprenticeVoiceIntroResponse[]> => {
  const resp: ApprenticeVoiceIntroResponse[] = await apiClient
    .post('/apprentices/voice-intro', data, {
      timeout: 90000
    })
    .then(res => res.data)
  return resp
}

export const deleteVoiceIntro = async (id: string) => {
  const resp = await apiClient.delete(`/apprentices/voice-intro/${id}`)
  return resp.data
}
