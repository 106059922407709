import { isEmpty } from 'lodash'

export const removeEmptyValues = (obj: Record<string, any>) => {
  if (isEmpty(obj)) return obj

  const keys = Object.keys(obj)

  return keys.reduce((result, key) => {
    let value = obj[key]
    if (typeof value === 'object') {
      value = removeEmptyValues(value)
    }
    if (!isEmpty(value)) {
      result[key] = value
    }
    return result
  }, {})
}
