import { GAAnalytics } from '@/components/shared/misc/Partial'
import { title as pageTitle } from '@/utils/lib'
import { UserRole } from '@qureos/types'
import { reverse } from 'lodash'
import Head from 'next/head'
import { FunctionComponent, ReactNode } from 'react'
import { Footer } from '../../misc/Footer'
import { Header } from '../../misc/Header'
import { SeoOGMetaType } from 'src/types/seo'
import { ShouldRender } from '../../misc'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { Breadcrumb } from 'antd'
import Link from 'next/link'
import { getBreadCrumbItems } from '@/utils/static-helpers/breadcrumb-helper'

interface Props {
  defaultWidth?: boolean
  title?: string
  subContent?: () => JSX.Element
  userRole?: UserRole
  meta?: string
  metaCanonical?: string
  seoOgMeta?: SeoOGMetaType
  metaJobs?: string
  showBreadCrumbs?: boolean
  searchSlug?: string
  useNoIndex?: boolean
  useNoIndexFollow?: boolean
  children?: ReactNode
  headerActions?: JSX.Element
}

export const MainTemplate: FunctionComponent<Props> = ({
  children,
  defaultWidth = true,
  title,
  subContent,
  userRole = UserRole.STUDENT,
  meta: metaDescription,
  metaCanonical,
  seoOgMeta,
  metaJobs,
  searchSlug,
  useNoIndex = false,
  useNoIndexFollow = false,
  showBreadCrumbs = false,
  headerActions
}) => {
  const titles: string[] = ['Qureos']
  const router = useRouter()
  const screens = useBreakpoint()

  if (title && title !== '') {
    titles.push(title)
  }

  const breadCrumbItems = getBreadCrumbItems({
    searchSlug,
    eventtype: '',
    eventID: '',
    routePath: router.asPath,
    screens
  })

  const breadCrumbs = breadCrumbItems[router.pathname]
  return (
    <>
      <Head>
        <title>{pageTitle(reverse(titles))}</title>
        <meta name="description" content={metaDescription} />
        <ShouldRender check={useNoIndex}>
          <meta name="robots" content="noindex" />
        </ShouldRender>
        <ShouldRender check={useNoIndexFollow}>
          <meta name="robots" content="noindex,follow" />
        </ShouldRender>
        {metaCanonical && <link rel="canonical" href={metaCanonical} />}
        {seoOgMeta && (
          <>
            <meta property="og:title" content={seoOgMeta.title} />
            <meta property="og:description" content={seoOgMeta.description} />
            <meta property="og:url" content={metaJobs || seoOgMeta.url} />
            <meta property="og:site_name" content={seoOgMeta.siteName} />
          </>
        )}
        {metaJobs && (
          <>
            <meta property="og:url" content={metaJobs} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={metaJobs} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={metaJobs} />
          </>
        )}
      </Head>

      <Header userRole={userRole} headerActions={headerActions} />

      <main className="flex flex-col items-center banner-main">
        <GAAnalytics />
        <ShouldRender check={showBreadCrumbs}>
          {breadCrumbs && (
            <Breadcrumb
              className="p-4 lg:px-12 pb-0 font-inter w-full md:w-[95%] mx-auto"
              separator={
                <ChevronRightIcon className="h-5 w-4 pb-[1px] text-gray-400 inline" />
              }
            >
              {breadCrumbs.map(item => {
                return (
                  <Breadcrumb.Item
                    className="cursor-pointer"
                    key={item.key as string}
                  >
                    {item.path ? (
                      <Link href={item.path} key={item.key as string}>
                        <span className="font-medium text-gray-500">
                          {item.label}
                        </span>
                      </Link>
                    ) : (
                      <span className="font-medium text-gray-500">
                        {item.label}
                      </span>
                    )}
                  </Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
          )}
        </ShouldRender>
        <section
          className={clsx(
            ' w-full h-auto',
            !defaultWidth && 'md:w-1/2 sm:w-full lg:w-1/3 xl:w-1/3'
          )}
        >
          <div
            className={clsx(
              !defaultWidth && 'p-8 bg-white rounded shadow-md',
              'flex justify-center'
            )}
          >
            {children}
          </div>
          {subContent && subContent()}
        </section>
      </main>

      <Footer />
    </>
  )
}
